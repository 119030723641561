import React, {Fragment} from 'react'
import Customers from '../../../components/customer/Customers'

function Customer() {
  return (
    <Fragment>
      <Customers/>
    </Fragment>
  )
}

export default Customer